import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { replaceURL } from '../../utils'
import './ContentSectionsColumns.scss'

const ContentSections = ({ sections }) => (
  <div className="content-sections-wrapper flex flex-wrap">
    {sections.map((section, index) => (
      <div className="w-full content-section-column sm:w-1/2 lg:w-1/3">
        <div className="section-inner">
          <Img fluid={section.image.localFile.childImageSharp.fluid} />
          <div className="text-wrapper">
            <Link to={replaceURL(section.button_url)}>
              <h3 className="normal-case">{section.title}</h3>
            </Link>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: section.content,
              }}
            />
            {section.button_text && section.button_url && (
              <Link
                to={replaceURL(section.button_url)}
                className="button pink inline-block"
              >
                {section.button_text}
              </Link>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
)

export default ContentSections
