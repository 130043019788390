import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ContentSectionsColumns from '../components/ContentSectionsColumns/ContentSectionsColumns'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

export const PageTemplate = ({ title, content, introSections }) => {
  return (
    <div className="container max-w-6xl">
      {introSections && <ContentSectionsColumns sections={introSections} />}
      <div className="container max-w-3xl">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout removePadding="true">
      <SEO title={page.title} />
      <PageTemplate
        title={page.title}
        content={page.content}
        introSections={page.acf.intro_sections}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query LandingPageColumnsById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        intro_sections {
          title
          content
          button_text
          button_url
          # button {
          #   title
          #   url
          # }
          image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 400
                  maxHeight: 160
                  quality: 100
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
